import { CustomerCartDto, CartItemDto, CustomerStoreCartItemDto, CustomerStoreCartDto, paymentModeDto, SaleOfferQuery, salesOfferDto, salesOfferDetailsDto } from "../dtos";
import { PaginatedRequestParams, PaginatedResponse } from "../../../../../common/pagination";
import { CartRepository } from "../../data";
import { CustomerCartMapper } from "../../data/mappers";
import { PartnerDto } from "../../../../partners/domain";
import { JObject } from "../../../../../common/data/models";
import {salesOfferMapper} from '../mappers/sales-offer';

export class CartService {

    private readonly repository: CartRepository;

    constructor() {
        this.repository = new CartRepository();
    }

    public async oldSearch(arg: PaginatedRequestParams): Promise<PaginatedResponse<CustomerCartDto>> {
        return this.repository.oldSearch(arg);
    }
    public async search(arg: PaginatedRequestParams): Promise<PaginatedResponse<CustomerCartDto>> {
        return this.repository.search(arg);
    }
    public async addItem(item: CartItemDto): Promise<number> {
        console.log(item);
        const form = {
            "customerId": item.customer!.partnerId!,
            "storeProductId": item.storeProduct!.storeProductId!,
            "quantity": item.quantity!,
            "priceListId": item.priceListId!,
            "bonusType": item.bonusType!,
        }
        return this.repository.addItem(form).then(res => res);
    }
    public async addOfferItem(item: CartItemDto): Promise<number> {
        console.log(item);
        const form = {
            "customerId": item.customer!.partnerId!,
            "salesOfferId": item.salesOfferId,
            "quantity": item.quantity!,
            "priceListId": item.priceListId!
        }
        return this.repository.addOfferItem(form).then(res => res);
    }

    public async updateItem(item: CustomerStoreCartItemDto): Promise<boolean> {
        const form = {
            "cartItemId": item.cartItemId,
            "quantity": item.quantity,
            "retailPrice": item.retailPrice,
            "discountPercent": item.discountPercent,
            "bonusType": item.bonusType!,
        }
        return this.repository.updateItem(form).then(res => res);
    }

    public async deleteItem(item: CustomerStoreCartItemDto): Promise<boolean> {
        return this.repository.deleteItem(item.cartItemId!).then(res => res);
    }

    public async deleteCart(cart: CustomerStoreCartDto): Promise<boolean> {
        return this.repository.deleteCart(cart.cartId!).then(res => res);
    }

    public async checkout(cart: CustomerStoreCartDto): Promise<boolean> {
        return this.repository.checkout(cart).then(res => res);
    }
    public searchSalesoffer(e: SaleOfferQuery, request: PaginatedRequestParams): Promise<PaginatedResponse<salesOfferDto>> {
       
        return this.repository.searchSalesoffer(e,request).then(res => {
            return {
                ...res!,
                data: { ...res!.data!, content: res!.data!.content.map(e => salesOfferMapper.fromJson(e)) ?? [] },
            };
        });;
    }

    public async checkoutAll(cart: CustomerCartDto, data?: any): Promise<boolean> {
        const valid = cart.carts ? Array.from(cart.carts).filter(e => e.showPlaceOrder).map(e => e.cartId) : undefined;
        if (!valid || !valid.length) {
            return Promise.reject();
        }
        const form = {
            "cartsCheckout": [data],
        }
        return this.repository.checkoutAll(form).then(res => res);
    }

    public async getDetails(id: number, getCustomer: boolean, priceListId?: any, salesOfferId?: any): Promise<CustomerCartDto> {
        return this.repository.getDetails(id, getCustomer, priceListId,salesOfferId).then(res => CustomerCartMapper.toDto(res));
    }

    public async getDetails1(id: number, getCustomer: boolean): Promise<CustomerCartDto> {
        return this.repository.getDetails1(id, getCustomer).then(res => CustomerCartMapper.toDto(res));
    }
    public async searchItems(id: number, arg: PaginatedRequestParams): Promise<PaginatedResponse<CustomerStoreCartItemDto>> {
        return this.repository.searchItems(id,arg);//.then(res => CustomerCartMapper.toDto(res));
    }

    public async getCustomer(id: number): Promise<PartnerDto> {
        return this.repository.getCustomer(id);
    }
    public async getItemDetails(data: JObject): Promise<CustomerStoreCartItemDto> {
        return this.repository.getItemDetails(data);
    }
    public async getPackageDetails(data:number): Promise<Array<salesOfferDetailsDto>>{
        return this.repository.getSalesOfferDetails(data);
    }
    public async getPaymentsMode(priceListId: number): Promise<Array<paymentModeDto>> {
        return this.repository.getPaymentModes(priceListId);
    }


}
