import React from 'react'
import { PrimeIcons } from 'primereact/api';
import { useHistory } from 'react-router-dom';
import { TawreedAction, TawreedActionName } from '../../../../../common/actions';
import { TawreedTable, TawreedTableProps } from '../../../../../common/components/table';
import { Routes } from '../../../../../router';
import { CustomerCartDto, CartService } from '../../domain';
import { CssUtils } from "../../../../../helpers/css";
import { GlobalContext } from "../../../../../context";
import { TawreedTableFormatters } from '../../../../../common/components/table/formatters';

export const CartsTable: React.FC = () => {

    const history = useHistory();
    const { auth: { user } } = React.useContext(GlobalContext);
    const service: CartService = React.useMemo(() => new CartService(), []);

    const onNew = (): Promise<void> => {
        return new Promise((resolve) => {
            console.log(user?.roleCode);
            if (user?.roleCode === 'CUSTOMER_ADMIN') { history.push(Routes.SalesCartNew.replace(":id", user!.partnerId!.toString())) }
            else
                history.push(Routes.SalesCartsNew);
            resolve();
        })
    };

    const onForm = React.useCallback(
        (sender?: CustomerCartDto): Promise<void> => {
            return new Promise((resolve) => {
                let route = Routes.SalesCartsDetails.replace(':id', sender!.cartId!.toString());
                if (sender?.priceListId)
                    route += `?priceListId=${sender!.priceListId}`;
                if (sender?.salesOfferId)
                    route += `&&salesOfferId=${sender!.salesOfferId}`;
                
                history.push(route);
                resolve();
            });
        },
        [history]);
        const getDependOnRole = () => {
            const arr = [];
            if (user?.roleCode !== 'STORE_ADMIN')
                arr.push({
                    field: "store.partnerName",
                    header: "lbl_store_products_store_name",
                    sortable: true,
                    sortField: 'store',
                    filter: true,
                    filterField: 'store',
                    style: CssUtils.width('30%'),
                })
            return arr;
        }
    

    const tawreed: TawreedTableProps = {
        header: true,
        pagination: true,
        dataKey: 'customer',
        name: 'tbl.sales.carts',
        title: 'lbl_sales_carts',
        ds: {
            mode: 'lazy',
            onSearch: (req) => service.search(req),
        },
        columns: [
            {
                dataType: 'text',
                field: "customer.partnerName",
                header: "lbl_sales_cart_customer",
                sortable: true,
                sortField: "customer",
                filter: true,
                filterField: 'customer',
                style: CssUtils.width('40%'),
            },
            ...getDependOnRole(),
            
            {
                field: "priceListName",
                header: "lbl_price_scheme_price_list_name",
                sortable: true,
                sortField: "priceListName",
                filter: true,
                filterField: 'priceListName',
                style: CssUtils.width('20%'),
            },
            // {
            //     field: "salesOfferTitle",
            //     header: "lbl_sales_offer_list_title",
            //     style: CssUtils.width('20%'),
            // },
            {
                field: "total",
                header: "lbl_sales_cart_order_totals",
                filter: true,
                filterField: 'total',
                sortable: true,
                ...TawreedTableFormatters.decimal('total', user?.scale, CssUtils.width('10%')),
            },
        ],
        toolbar: {
            actions: [
                new TawreedAction(TawreedActionName.CartsCreate, 'stateless', 'lbl_new', PrimeIcons.PLUS, onNew),
            ],
        },
        row: {
            onClick: new TawreedAction(TawreedActionName.CartsDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onForm),
        },
        selection: {
            mode: 'single',
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'customer',
                sortOrder: -1,
                multiSortMeta: undefined,
            }
        },
        filter: {
            filterDisplay: 'menu',
            initial: {
                'store': {
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'contains' }],
                },
                'customer': {
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'contains' }],
                },
                'total': {
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'equals' }],
                },
                'priceListName':{
                    operator: 'AND',
                    constraints: [{ value: null, matchMode: 'equals' }],
                },
            },
        },
    }

    return (<TawreedTable  {...tawreed} />);
}
